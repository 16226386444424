import React from 'react';
import createButtonLike from './createButtonLike';

const ButtonComponent = React.forwardRef(
  (
    props: React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    ref: React.Ref<HTMLButtonElement>
  ) => <button {...props} ref={ref} />
);

const Button = createButtonLike(ButtonComponent, 'Button');

export default Button;
