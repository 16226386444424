import React, { useRef, useEffect, useState } from "react";

import v1 from "./microbit-drawing.svg";
import v2 from "./microbit-drawing-2.svg";
import styles from "./DesignYourMicrobit.module.scss";
import Link from "../../components/Link/Link";
import WithSpacing from "../../components/Spacing/Spacing";
import Text from "../../components/Text/Text";
import Checkbox from "../../components/Inputs/Checkbox/Checkbox";
import Button from "../../components/Button/StandardButton/Button";
const versions = { v1, v2 };

interface Actions {
  toSVG: () => void;
  toPNG: () => void;
  setShadow?: (show: boolean) => void;
}

interface DesignYourMicrobotProps {
  version: "v1" | "v2";
}

const DesignYourMicrobit = ({ version }: DesignYourMicrobotProps) => {
  const [shadow, setShadow] = useState(true);
  const [, setReady] = useState(false);
  const actions = useRef<Actions>({
    toPNG: () => {
      /* Not yet ready */
    },
    toSVG: () => {
      /* Not yet ready */
    }
  });
  useEffect(() => {
    const timeout = setInterval(() => {
      if (initializeSvg(actions)) {
        window.clearTimeout(timeout);
        setReady(true);
      }
    }, 50);
  }, []);
  return (
    <div className={styles.root}>
      <div>
        <object id="svg" data={versions[version]} type="image/svg+xml">
          micro:bit device
        </object>
      </div>
      <WithSpacing>
        <div className={styles.controls}>
          <noscript>
            This page requires JavaScript to edit the micro:bit.
          </noscript>
          <Text variant="default">
            <Link to="/design-your-microbit/v1/">Original micro:bit</Link> or{" "}
            <Link to="/design-your-microbit/v2/">new micro:bit</Link>
          </Text>
          <Checkbox
            id="shadow"
            value="shadow"
            checked={shadow}
            label={
              <Text variant="default" as="span">
                Shadow
              </Text>
            }
            disabled={!actions?.current?.setShadow}
            onChange={() => {
              const newValue = !shadow;
              actions.current!.setShadow!(newValue);
              setShadow(newValue);
            }}
          />
          <div className={styles.buttonBar}>
            <Button onClick={() => actions.current.toSVG()}>
              Download SVG
            </Button>
            <Button onClick={() => actions.current.toPNG()}>
              Download PNG
            </Button>
          </div>
        </div>
      </WithSpacing>
    </div>
  );
};

const initializeSvg = (actions: React.RefObject<Actions>) => {
  const object = document.querySelector("object");
  if (!object) {
    return false;
  }
  const svgObject = object as HTMLObjectElement;
  const svgDocument = svgObject.contentDocument;
  if (!svgDocument || svgDocument.readyState !== "complete") {
    return false;
  }

  // We always display the unlit LEDs and toggle the corresponding lit ones.
  const unlitLedsSelector = "#UnlitLEDs";
  const litLedsSelector = "#LEDsOn";
  // These need proper IDs.
  const buttonASelector = "#ButtonGroup";
  const buttonBSelector = "#use8162";
  const colourLayerSelectors = [
    "#ColourFringeGreen",
    "#ColourFringeRed",
    "#ColourFringeBlue",
    "#ColourFringeYellow"
  ];
  let activeColourLayer = 0;

  const colourLayers = colourLayerSelectors.map(id =>
    querySvgSelector(svgDocument, id)
  );
  const showColourLayer = (layer: SVGElement) => {
    colourLayers.forEach(other => {
      other.style.display = other === layer ? "inline" : "none";
    });
  };
  colourLayers.forEach(layer => {
    layer.style.pointerEvents = "all";
    layer.onclick = () => {
      activeColourLayer = (activeColourLayer + 1) % colourLayers.length;
      showColourLayer(colourLayers[activeColourLayer]);
    };
  });
  showColourLayer(colourLayers[Math.floor(Math.random() * 4)]);

  // v2 only
  const microphoneLayer = queryOptionalSvgSelector(svgDocument, "#Microphone");
  const microphoneOnLayer = queryOptionalSvgSelector(
    svgDocument,
    "#MicrophoneON"
  );
  if (microphoneOnLayer && microphoneLayer) {
    const toggleMicrophone = () => toggleDisplay(microphoneOnLayer);
    microphoneLayer.onclick = toggleMicrophone;
    microphoneOnLayer.onclick = toggleMicrophone;
  }

  const allUnlit = allDescendentUseElements(svgDocument, unlitLedsSelector);
  const allLit = allDescendentUseElements(svgDocument, litLedsSelector);
  const clearLit = () => allLit.forEach(led => (led.style.display = "none"));
  const lightAll = () => allLit.forEach(led => (led.style.display = "inline"));

  for (let c = 0; c < 5; c = c + 1) {
    for (let r = 0; r < 5; r = r + 1) {
      // Note by row vs by column here (unintentional SVG difference).
      const lit = allLit[c * 5 + r];
      const unlit = allUnlit[r * 5 + c];
      lit.onclick = () => (lit.style.display = "none");
      // Use the whole group for a larger click target.
      unlit.style.pointerEvents = "all";
      unlit.onclick = () =>
        (lit.style.display = lit.style.display === "none" ? "inline" : "none");
    }
  }

  querySvgSelector(svgDocument, buttonASelector).onclick = lightAll;
  querySvgSelector(svgDocument, buttonBSelector).onclick = clearLit;
  clearLit();

  const svgToDataUrl = () => {
    const svgString = new XMLSerializer().serializeToString(svgDocument);
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;
  };

  actions.current!.toSVG = () => {
    download(svgToDataUrl(), "custom-microbit.svg");
  };
  actions.current!.toPNG = () => {
    const img = document.createElement("img");
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = svgObject.clientWidth;
      canvas.height = svgObject.clientHeight;
      const context = canvas.getContext("2d");
      if (!context) {
        throw new Error("Missing 2d context");
      }
      context.drawImage(img, 0, 0);
      download(canvas.toDataURL("image/png"), "custom-microbit.png");
    };
    img.setAttribute("src", svgToDataUrl());
  };
  actions.current!.setShadow = (show: boolean) => {
    for (const shadowId of ["#Shadow", "#layer1"]) {
      const element = queryOptionalSvgSelector(svgDocument, shadowId);
      if (element) {
        element.style.display = show ? "inline" : "none";
      }
    }
  };

  return true;
};

const toggleDisplay = (element: SVGElement) => {
  element.style.display =
    element.style.display === "inline" ? "none" : "inline";
};

const queryOptionalSvgSelector = (svgDocument: Document, selector: string) => {
  const element = svgDocument.querySelector(selector);
  if (!element) {
    return undefined;
  }
  return element as SVGElement;
};

const querySvgSelector = (svgDocument: Document, selector: string) => {
  const element = queryOptionalSvgSelector(svgDocument, selector);
  if (!element) {
    throw new Error(`Unexpectedly missing ${selector}`);
  }
  return element;
};

const allDescendentUseElements = (svgDocument: Document, selector: string) =>
  Array.from(
    querySvgSelector(svgDocument, selector).querySelectorAll("use")
  ) as SVGUseElement[];

const download = (dataUrl: string, filename: string) => {
  const element = document.createElement("a");
  element.setAttribute("href", dataUrl);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export default DesignYourMicrobit;
