import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  stroke?: string;
  strokeWidth?: number;
  width?: number;
};

const CheckboxChecked = ({
  className = '',
  fill = 'none',
  height = 20,
  stroke,
  strokeWidth,
  width = 20,
}: Props) => (
  <svg width={width} height={height} className={className}>
    <g fill={fill} fillRule="evenodd" stroke={stroke}>
      <rect
        width={18.5}
        height={18.5}
        x={0.75}
        y={0.75}
        strokeWidth={strokeWidth}
        rx={2}
      />
      <path
        strokeLinecap="round"
        strokeWidth={1.2}
        d="M5 10.76L8.214 14 15 5"
      />
    </g>
  </svg>
);

export default CheckboxChecked;
