import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  stroke?: string;
  strokeWidth?: number;
  width?: number;
};

const CheckboxIndeterminate = ({
  className = '',
  fill = 'none',
  height = 20,
  stroke,
  strokeWidth,
  width = 20,
}: Props) => (
  <svg width={width} height={height} className={className}>
    <g fill={fill} fillRule="evenodd" stroke={stroke}>
      <rect
        x={0.75}
        y={0.75}
        width={18.5}
        height={18.5}
        rx={2}
        strokeWidth={strokeWidth}
      />
      <path
        d="M4.59 10h10.68"
        strokeLinecap="round"
        strokeWidth={1.2}
        fill="none"
        stroke={stroke}
      />
    </g>
  </svg>
);

export default CheckboxIndeterminate;
