import React, { ReactNode, useState } from 'react';
import CheckboxChecked from './_checkboxChecked';
import CheckboxUnchecked from './_checkboxUnchecked';
import CheckboxIndeterminate from './_checkboxIndeterminate';
import styles from './Checkbox.module.scss';
import classnames from 'classnames';

interface CheckboxProps {
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  id: string;
  label: ReactNode;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  renderRight?: ReactNode;
  selected?: boolean;
  style?: React.CSSProperties;
  value: string;
}

const Checkbox = ({
  checked = false,
  disabled = false,
  id,
  label,
  indeterminate,
  onChange,
  renderRight,
  style,
  value = '',
}: CheckboxProps) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  return (
    <div className={styles.checkboxContainer}>
      <label
        className={classnames(
          styles.checkboxLabel,
          disabled && styles.disabled,
          isFocused && styles.focused
        )}
      >
        <div>
          {indeterminate ? (
            <CheckboxIndeterminate
              className={classnames(styles.checkboxIcon, {
                [`${styles.checkboxChecked}`]: indeterminate,
              })}
            />
          ) : checked ? (
            <CheckboxChecked
              className={classnames(styles.checkboxIcon, {
                [`${styles.checkboxChecked}`]: checked,
              })}
            />
          ) : (
            <CheckboxUnchecked className={styles.checkboxIcon} />
          )}
        </div>

        <input
          type="checkbox"
          ref={(el) => el && (el.indeterminate = !!indeterminate)}
          name={id}
          checked={checked}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          disabled={disabled}
          className={classnames(styles.checkbox, disabled && styles.disabled)}
          onChange={onChange}
          value={value}
          style={style}
        />
        {label}
      </label>
      {renderRight}
    </div>
  );
};

export default Checkbox;
