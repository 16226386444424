import classnames from "classnames";
import React, { ReactElement } from "react";
import { Col } from "../Grid";
import styles from "./Spacing.module.scss";

interface SpacingProps {
  children: ReactElement;
}

type ColProps = React.ComponentPropsWithoutRef<typeof Col>;
export const SpacedCol = (props: ColProps) => (
  <WithSpacing>
    <Col {...props} />
  </WithSpacing>
);

const WithSpacing = ({ children }: SpacingProps) =>
  React.cloneElement(children, {
    className: classnames(styles.root, children.props.className)
  });

export default WithSpacing;
