import React from 'react';

type Props = {
  className?: string;
  fill?: string;
  height?: number;
  stroke?: string;
  strokeWidth?: number;
  width?: number;
};

const CheckboxUnchecked = ({
  className = '',
  fill = '#FFF',
  height = 20,
  stroke,
  strokeWidth = 1.5,
  width = 20,
}: Props) => (
  <svg width={width} height={height} className={className}>
    <rect
      width={18.5}
      height={18.5}
      x={0.75}
      y={0.75}
      fill={fill}
      fillRule="evenodd"
      stroke={stroke}
      strokeWidth={strokeWidth}
      rx={2}
    />
  </svg>
);

export default CheckboxUnchecked;
