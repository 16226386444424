import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import Card from "../../components/Card/Card";
import Link from "../../components/Link/Link";
import WithSpacing from "../../components/Spacing/Spacing";
import Text from "../../components/Text/Text";
import OneColumnLayout from "../../layouts/OneColumnLayout/OneColumnLayout";
import PageLayout from "../../layouts/PageLayout/PageLayout";
import { CommonContext } from "../../model/common";
import { GlobalMenus } from "../../model/menu";
import { PageMetadata } from "../../model/metadata";
import { SiteArea } from "../../utils/analytics";
import DesignYourMicrobit from "./DesignYourMicrobit";
import styles from "./DesignYourMicrobitPage.module.scss";

export const pageQuery = graphql`
  query DesignYourMicrobitPage($navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
  }
`;

const DesignYourMicrobitPage = ({
  pageContext,
  data: { menus },
  location
}: DesignYourMicrobitPageProps) => {
  const title = "micro:bit artwork tool";
  const metadata: PageMetadata = {
    title,
    page: {
      _id: `designYourMicrobit${pageContext.version}`
    } as any,
    alternates: []
  };
  return (
    <PageLayout
      siteArea={SiteArea.UNKNOWN}
      metadata={metadata}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <OneColumnLayout
        title={title}
        social={false}
        main={
          <>
            <WithSpacing>
              <Card type="main" className={styles.root}>
                <Text variant="default">
                  Use this to create bitmap or vector images of the BBC
                  micro:bit board.
                </Text>
                <Text variant="default">
                  You must refer to our{" "}
                  <Link to="/brand-guidelines/">brand guidelines</Link> when
                  using images of the micro:bit.
                </Text>
                <Text variant="default">
                  Click the micro:bit LEDs then export your image. Click the
                  fringe to change colour. Select original or new micro:bit and
                  drop-shadow as required. Download your image as PNG (bitmap)
                  or SVG (vector) format files.
                </Text>
                <Text variant="default">
                  By downloading images from this page you agree to follow our
                  brand guidelines for their use. These images are released
                  under a CC-BY-SA 4 licence.
                </Text>
              </Card>
            </WithSpacing>
            <div style={{ marginTop: "20px" }}>
              <DesignYourMicrobit version={pageContext.version} />
            </div>
          </>
        }
      />
    </PageLayout>
  );
};

export default DesignYourMicrobitPage;

interface DesignYourMicrobitPageProps {
  data: {
    menus: GlobalMenus;
  };
  location: WindowLocation;
  pageContext: CommonContext & { version: "v1" | "v2" };
}
